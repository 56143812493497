<template>
	<div style="position: relative;">
		<div class="mark"></div>
		<div class="header">
			<!-- 页眉 -->
			<!-- <div class="alert">{{alertStatus}}</div> -->
			<div class="content">
				<div class="left-img" @click="scrollIntoView('home')">
					<div class="name-title">宁德市千雪制冷设备有限公司</div>
					<div class="name-subtitle">Ningde Qianxue Refrigeration Equipment Co., Ltd.</div>
				</div>
				<div class="nav-min yx-icon" @click.stop="openNav()">
					&#xe681;
				</div>
				<ul class="nav">
					<li class="navitem" @click="goNext('home1')">公司首页</li>
					<li class="navitem" @click="goNext('about')">关于我们</li>
					<li class="navitem" @click="goNext('news')">新闻资讯</li>
					<li class="navitem" @click="goNext('case')">工程案例</li>
					<li class="navitem" @click="goNext2('product')">产品中心</li>
					<li class="navitem" @click="goNext2('process')">合作流程</li>
					<li class="navitem" @click="goNext2('service')">售后服务</li>
					<li class="navitem" @click="goNext2('contact')">联系我们</li>
				</ul>
			</div>
			<van-popup v-model="navShow" position="right" style="width: 65%;transform: translate3d(0,-100%,0);">
				<div class="nav-phone">
					<ul class="nav">
						<li class="navitem" @click="goNext('home1')">公司首页</li>
						<li class="navitem" @click="goNext('about')">关于我们</li>
						<li class="navitem" @click="goNext('news')">新闻资讯</li>
						<li class="navitem" @click="goNext('case')">工程案例</li>
						<li class="navitem" @click="goNext2('product')">产品中心</li>
						<li class="navitem" @click="goNext2('process')">合作流程</li>
						<li class="navitem" @click="goNext2('service')">售后服务</li>
						<li class="navitem" @click="goNext2('contact')">联系我们</li>
					</ul>
				</div>
			</van-popup>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'header-nav', // 头部
		data() {
			return {
				aboutShow: 1,
				newShow: 1,
				caseShow: 1,
				loading: false,
				navShow: false,
				alertStatus: '宁德市千雪制冷设备有限公司欢迎您的到访！',
			}
		},
		props:{
			tab:{
				type:Boolean,
				default:false
			},
		},
		computed: {
		},
		watch: {
		},
		methods: {
			goNext(val) {
				this.navShow = false;
				if(this.tab) {
					if(val == 'home1') {
						this.scrollIntoView(val);
					}
					else if(val == 'process') {
						console.log(val);
						this.$router.push({
							name: 'home',
							query:{
								name:val
							}
						})
					}
					else {
						this.$router.push({
							name: val,
						})
					}
				}
				else {
					this.scrollIntoView(val);
				}
			},
			goNext2(val) {
				this.$router.push({
					name: val,
				})
			},
			/* 跳转到指定res */
			scrollIntoView(name) {
				console.log(name);
				this.$router.push({
					name: 'home',
					query:{
						name:name
					}
				})
			},
			openNav() {
				this.navShow = true;
			},
			//跳转路由
			// downloadapp() {
			// 	this.$router.push({
			// 		name: 'download'
			// 	})
			// },
		}
	};
</script>

<style scoped="" lang="less">
	.mark{
		height: 140px;
		background: #000000;
	}
	.header {
		background-color: #FFFFFF;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 2000;
		font-size: 20px;
		/* 页眉 */
		.alert {
		  height: 35px;
		  padding: 0 0 0 324px;
		  font-size: 16px;
		  color: #B3B1AF;
		}
		.content {
			min-height: 60px;
			display: flex;
			flex-direction: column;
			
			.left-img {
				display: flex;
				flex-direction: column;
				height: 50px;
				text-align: left;
				padding: 20px 200px;
				.name-title {
					color: #136CC0;
					font-size:30px;
					font-weight:bold;
					font-family: Source Han Sans CN;
					letter-spacing: 4.5px;
				}
				.name-subtitle {
					color: #9C9D9E;
					font-size:20px;
				}
			}

			.nav {
				height: 50px;
				// width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-around;
				align-items: center;
				border-left: 1px solid rgba(255, 255, 255, 0.1);
				background-color: #136CC0;
				padding: 0 150px;
				.navitem {
					cursor: pointer;
					color: #FFFFFF;
					max-width: 150px;
					width: 100%;
					padding: 10px 0;
					font-size: 16px;
					font-weight:bold;
					&:hover {
						background-color: #1980E2;
					}
				}
			}

			.nav-min {
				display: none;
			}
		}
		
		.nav-phone {
			display: flex;
			flex-direction: column;
			background-color: #136CC0;
			width: 100%;
			
			.nav {
				margin-top: 20px;

				li {
					height: 40px;
					line-height: 40px;
					color: #FFFFFF;
					cursor: pointer;
					font-size: 15px;
					&:hover {
						background-color: #1980E2;
					}
				}
			}
		}
	}
	
/* 手机端 */
	@media (orientation:portrait) and (max-width:890px) {
		.mark{
			height: 60px !important;
		}
		.header .content .nav {
			display: none !important;
		}

		.content {
			flex-direction: row !important;
			justify-content: space-between !important;
			align-items: center;
			padding: 10px;
			min-height: 50px !important;
			
			.left-img {
				text-align: left;
				padding: 10px !important;
				height: 30px !important;
				.name-title {
					font-size: 16px !important;
				}
				.name-subtitle {
					font-size: 12px !important;
				}
			}

			.clear {
				display: none;
			}

			.nav-min {
				display: flex !important;
			}
		}
	}	
</style>
