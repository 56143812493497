import Fly from 'flyio/dist/npm/fly'
import {Toast} from 'vant';

const request = new Fly();

/* 错误提示 */
const errorPrompt = (err) => {
	switch (err.status){
		case 500:
			Toast('网络异常');
			break;
		default:
			Toast('网络异常');
			break;
	}
}
//添加 请求 拦截器
request.interceptors.request.use((request) => {
	// Toast.loading({
	// 	message: '加載中...',
	// 	forbidClick: true
	// });
	//request.headers['Accept-Language'] = store.state.language.lang; //http请求头，
	console.log('請求攔截器:', request);
	return request
})

//添加 响应 拦截器，响应拦截器会在then/catch处理之前执行
request.interceptors.response.use((response, promise) => {
	Toast.clear; //关闭加载
	if (!(response && response.data)) {
		errorPrompt(response)
	} else if (response.data.code !== 0) {
		Toast(response.data.msg || '未知錯誤');
	}
	//console.log(JSON.stringify(response));
	return promise.resolve(response.data);
}, (err, promise) => {
	Toast.clear; //关闭加载
	errorPrompt(err)
	return promise.reject(err)
})

export default request
