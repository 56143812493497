import request from './request';
import {apiURL,port} from '@/service.js';

const baseURL = apiURL;// + ':' + port;

request.config.headers['Content-Type'] = 'application/json'; //http请求头，
request.config.baseURL = baseURL;
request.config.timeout = 5000; //超时设置为5s
// request.config.withCredentials = true; //跨域时是否发送cookie
/** @description api接口 */
const api = {
	
	menuList: (data) => request.get('/app/category',data), // 栏目列表
	bannerList: (data) => request.get('/app/banner',data), // 轮播图列表
	newsList: (id,data) => request.get('/app/article/'+id,data), // 新闻资讯列表
	newsDetail: (id) => request.get('/app/article/detail/'+id), // 新闻资讯详情
	
	feedback: (data) => request.post('/app/feedback',data, {'header':{'content-type':'application/json'}}), // 提交反馈
	
	settingInfoList: (data) => request.get('/app/dict/list',data), // 配置信息
	settingInfo: (data) => request.get('/app/dict',data), // 配置信息
	
}
export default api
